import React from "react";
import "./App.scss";
import { ApplicationRoutes } from "./routes/Routes";
export default function App() {
  return (
    <>
      <ApplicationRoutes></ApplicationRoutes>
    </>
  );
}
