interface Props {
  className?: string;
}

const PDFLogo = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="250"
      zoomAndPan="magnify"
      viewBox="0 0 150 149.999998"
      height="250"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="589eaebd92">
          <path
            d="M 32.347656 30.097656 L 114 30.097656 L 114 111.847656 L 32.347656 111.847656 Z M 32.347656 30.097656 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <rect
        x="-15"
        width="180"
        fill="#ffffff"
        y="-15"
        height="179.999997"
        fill-opacity="1"
      />
      <rect
        x="-15"
        width="180"
        fill="#ffffff"
        y="-15"
        height="179.999997"
        fill-opacity="1"
      />
      <path
        fill="#f3eeea"
        d="M 96.492188 96.902344 L 56.945312 96.902344 C 56.550781 96.902344 56.242188 96.59375 56.242188 96.199219 L 56.242188 77.035156 C 56.242188 76.640625 56.550781 76.332031 56.945312 76.332031 L 83.289062 76.332031 L 83.289062 66.492188 L 56.945312 66.492188 C 56.550781 66.492188 56.242188 66.1875 56.242188 65.789062 L 56.242188 45.46875 C 56.242188 45.101562 56.550781 44.796875 56.945312 44.796875 L 82.800781 44.796875 L 97.195312 61.054688 L 97.195312 96.199219 C 97.195312 96.59375 96.890625 96.902344 96.492188 96.902344 Z M 82.800781 44.796875 L 97.195312 61.054688 L 83.503906 61.054688 C 83.105469 61.054688 82.800781 60.746094 82.800781 60.382812 Z M 82.800781 44.796875 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#58535a"
        d="M 92.242188 83.699219 L 61.195312 83.699219 C 60.828125 83.699219 60.492188 83.394531 60.492188 82.996094 C 60.492188 82.628906 60.828125 82.324219 61.195312 82.324219 L 92.242188 82.324219 C 92.609375 82.324219 92.917969 82.628906 92.917969 82.996094 C 92.917969 83.394531 92.609375 83.699219 92.242188 83.699219 Z M 92.242188 83.699219 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#58535a"
        d="M 92.242188 90.910156 L 61.195312 90.910156 C 60.828125 90.910156 60.492188 90.605469 60.492188 90.238281 C 60.492188 89.839844 60.828125 89.535156 61.195312 89.535156 L 92.242188 89.535156 C 92.609375 89.535156 92.917969 89.839844 92.917969 90.238281 C 92.917969 90.605469 92.609375 90.910156 92.242188 90.910156 Z M 92.242188 90.910156 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ebe1d8"
        d="M 97.195312 61.054688 L 83.503906 61.054688 C 83.105469 61.054688 82.800781 60.746094 82.800781 60.382812 L 82.800781 44.796875 Z M 97.195312 61.054688 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#58535a"
        d="M 79.257812 52.039062 L 61.195312 52.039062 C 60.828125 52.039062 60.492188 51.734375 60.492188 51.367188 C 60.492188 50.96875 60.828125 50.664062 61.195312 50.664062 L 79.257812 50.664062 C 79.621094 50.664062 79.929688 50.96875 79.929688 51.367188 C 79.929688 51.734375 79.621094 52.039062 79.257812 52.039062 Z M 79.257812 52.039062 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#58535a"
        d="M 79.257812 59.28125 L 61.195312 59.28125 C 60.828125 59.28125 60.492188 58.945312 60.492188 58.578125 C 60.492188 58.179688 60.828125 57.875 61.195312 57.875 L 79.257812 57.875 C 79.621094 57.875 79.929688 58.179688 79.929688 58.578125 C 79.929688 58.945312 79.621094 59.28125 79.257812 59.28125 Z M 79.257812 59.28125 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#4d5c69"
        d="M 83.992188 77.707031 L 49.703125 77.707031 C 49.308594 77.707031 49 77.402344 49 77.035156 L 49 65.789062 C 49 65.394531 49.308594 65.085938 49.703125 65.085938 L 83.992188 65.085938 C 84.359375 65.085938 84.664062 65.394531 84.664062 65.789062 L 84.664062 77.035156 C 84.664062 77.402344 84.359375 77.707031 83.992188 77.707031 Z M 83.992188 77.707031 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#fefefe"
        d="M 59.910156 75.265625 C 59.542969 75.265625 59.238281 74.957031 59.238281 74.5625 L 59.238281 68.265625 C 59.238281 67.867188 59.542969 67.5625 59.910156 67.5625 L 61.5 67.5625 C 62.753906 67.5625 63.761719 68.570312 63.761719 69.824219 C 63.761719 71.078125 62.753906 72.117188 61.5 72.117188 L 60.613281 72.117188 L 60.613281 74.5625 C 60.613281 74.957031 60.308594 75.265625 59.910156 75.265625 Z M 60.613281 70.710938 L 61.5 70.710938 C 61.988281 70.710938 62.386719 70.3125 62.386719 69.824219 C 62.386719 69.335938 61.988281 68.9375 61.5 68.9375 L 60.613281 68.9375 Z M 60.613281 70.710938 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#fefefe"
        d="M 66.390625 75.265625 L 65.257812 75.265625 C 64.863281 75.265625 64.554688 74.957031 64.554688 74.5625 L 64.554688 68.265625 C 64.554688 67.867188 64.863281 67.5625 65.257812 67.5625 L 66.390625 67.5625 C 67.886719 67.5625 69.109375 68.785156 69.109375 70.28125 L 69.109375 72.511719 C 69.109375 74.042969 67.886719 75.265625 66.390625 75.265625 Z M 65.960938 73.859375 L 66.390625 73.859375 C 67.125 73.859375 67.734375 73.277344 67.734375 72.511719 L 67.734375 70.28125 C 67.734375 69.550781 67.125 68.9375 66.390625 68.9375 L 65.960938 68.9375 Z M 65.960938 73.859375 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#fefefe"
        d="M 70.578125 75.265625 C 70.210938 75.265625 69.90625 74.957031 69.90625 74.5625 L 69.90625 68.265625 C 69.90625 67.867188 70.210938 67.5625 70.578125 67.5625 L 73.753906 67.5625 C 74.121094 67.5625 74.425781 67.867188 74.425781 68.265625 C 74.425781 68.632812 74.121094 68.9375 73.753906 68.9375 L 71.28125 68.9375 L 71.28125 70.710938 L 73.753906 70.710938 C 74.121094 70.710938 74.425781 71.015625 74.425781 71.414062 C 74.425781 71.78125 74.121094 72.117188 73.753906 72.117188 L 71.28125 72.117188 L 71.28125 74.5625 C 71.28125 74.957031 70.972656 75.265625 70.578125 75.265625 Z M 70.578125 75.265625 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <g clip-path="url(#589eaebd92)">
        <path
          fill="#ebe1d8"
          d="M 73.082031 30.097656 C 95.605469 30.097656 113.851562 48.339844 113.851562 70.832031 C 113.851562 93.355469 95.605469 111.601562 73.082031 111.601562 C 50.589844 111.601562 32.347656 93.355469 32.347656 70.832031 C 32.347656 48.339844 50.589844 30.097656 73.082031 30.097656 Z M 73.082031 33.550781 C 93.679688 33.550781 110.398438 50.234375 110.398438 70.832031 C 110.398438 91.429688 93.679688 108.148438 73.082031 108.148438 C 52.484375 108.148438 35.800781 91.429688 35.800781 70.832031 C 35.800781 50.234375 52.484375 33.550781 73.082031 33.550781 Z M 73.082031 33.550781 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <path
        fill="#4d5c69"
        d="M 73.082031 32.386719 C 94.320312 32.386719 111.527344 49.625 111.527344 70.832031 C 111.527344 92.070312 94.320312 109.277344 73.082031 109.277344 C 51.875 109.277344 34.636719 92.070312 34.636719 70.832031 C 34.636719 49.625 51.875 32.386719 73.082031 32.386719 Z M 73.082031 33.550781 C 93.679688 33.550781 110.398438 50.234375 110.398438 70.832031 C 110.398438 91.429688 93.679688 108.148438 73.082031 108.148438 C 52.484375 108.148438 35.800781 91.429688 35.800781 70.832031 C 35.800781 50.234375 52.484375 33.550781 73.082031 33.550781 Z M 73.082031 33.550781 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default PDFLogo;
