interface Props {
  className?: string;
}

const LiveTextLogo = (props: Props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="200"
      zoomAndPan="magnify"
      viewBox="0 0 150 149.999998"
      height="200"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="bf01cb6cd1">
          <path
            d="M 8 9.304688 L 140 9.304688 L 140 146 L 8 146 Z M 8 9.304688 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9c9ca923c6">
          <path
            d="M 63 45 L 147.5625 45 L 147.5625 128 L 63 128 Z M 63 45 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="bdbc85f7b0">
          <path
            d="M 61 23.171875 L 93 23.171875 L 93 83 L 61 83 Z M 61 23.171875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="990ee07532">
          <path
            d="M 20.0625 67 L 77 67 L 77 137.171875 L 20.0625 137.171875 Z M 20.0625 67 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#bf01cb6cd1)">
        <path
          fill="#a5b2be"
          d="M 117.894531 16.519531 C 128.210938 23.25 134.46875 36.140625 137.390625 48.789062 C 140.316406 61.359375 139.910156 73.683594 137.960938 85.601562 C 136.09375 97.601562 132.761719 109.195312 126.019531 119.25 C 119.277344 129.304688 109.285156 137.816406 97.421875 142.277344 C 85.480469 146.816406 71.832031 147.304688 60.132812 143.007812 C 48.515625 138.628906 38.847656 129.464844 29.996094 119.410156 C 21.140625 109.4375 13.015625 98.65625 9.847656 86.25 C 6.597656 73.84375 8.222656 59.816406 13.910156 47.734375 C 19.597656 35.65625 29.34375 25.519531 40.878906 19.359375 C 52.335938 13.195312 65.660156 11.007812 79.46875 10.195312 C 93.28125 9.304688 107.578125 9.871094 117.894531 16.519531 Z M 117.894531 16.519531 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#ddb4a6"
        d="M 64.949219 61.472656 C 64.789062 62.136719 64.472656 62.746094 63.945312 63.167969 C 63.175781 63.78125 62.117188 63.859375 61.113281 63.910156 C 61.085938 63.910156 61.058594 63.910156 61.03125 63.910156 C 60.609375 63.9375 60.160156 63.9375 59.738281 63.9375 C 59.5 63.9375 59.234375 63.9375 59.023438 63.859375 C 58.890625 63.804688 58.785156 63.726562 58.679688 63.621094 C 58.574219 63.515625 58.464844 63.382812 58.359375 63.25 C 57.882812 62.585938 57.382812 61.949219 56.90625 61.289062 C 56.640625 60.941406 56.375 60.570312 56.351562 60.148438 C 56.324219 59.777344 56.507812 59.40625 56.695312 59.089844 C 57.144531 58.292969 57.699219 57.578125 58.335938 56.941406 C 58.492188 56.78125 58.625 56.648438 58.835938 56.570312 C 59.050781 56.492188 59.285156 56.492188 59.5 56.492188 C 60.953125 56.515625 62.515625 56.570312 63.652344 57.496094 L 64.949219 59.511719 C 65.082031 60.148438 65.109375 60.8125 64.949219 61.472656 Z M 64.949219 61.472656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ddb4a6"
        d="M 63.175781 74.886719 C 63.097656 74.992188 62.992188 75.125 62.859375 75.175781 C 62.753906 75.230469 62.621094 75.230469 62.488281 75.203125 C 62.117188 75.148438 61.746094 75.042969 61.429688 74.859375 C 61.113281 74.671875 60.847656 74.460938 60.558594 74.222656 C 60.027344 73.773438 59.5 73.347656 58.96875 72.898438 C 58.835938 72.792969 58.730469 72.683594 58.597656 72.578125 C 58.308594 72.339844 58.042969 72.074219 57.777344 71.808594 C 57.671875 71.679688 57.566406 71.570312 57.460938 71.4375 C 57.011719 70.882812 56.695312 70.246094 56.613281 69.558594 C 56.5625 68.949219 56.71875 68.363281 56.90625 67.78125 C 57.066406 67.277344 57.277344 66.773438 57.671875 66.457031 C 57.964844 66.21875 58.335938 66.113281 58.703125 66.003906 C 58.996094 65.925781 59.261719 65.875 59.550781 65.792969 C 60 65.6875 60.476562 65.632812 60.925781 65.582031 C 61.457031 65.527344 62.011719 65.503906 62.542969 65.527344 L 63.996094 66.695312 C 64.234375 67.648438 64.5 68.601562 64.578125 69.585938 C 64.738281 71.464844 64.289062 73.402344 63.175781 74.886719 Z M 63.175781 74.886719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#9c9ca923c6)">
        <path
          fill="#ddb4a6"
          d="M 63.890625 73.347656 C 69.605469 82.359375 79.898438 87.128906 88.707031 93.121094 C 98.839844 99.984375 107.464844 108.890625 115.324219 118.300781 C 117.175781 120.527344 119.027344 122.808594 121.382812 124.53125 C 126.304688 128.109375 133.261719 128.636719 138.660156 125.800781 C 144.03125 122.964844 147.550781 116.871094 147.257812 110.773438 C 147.207031 109.5 146.996094 108.230469 146.386719 107.117188 C 145.671875 105.789062 144.429688 104.808594 143.238281 103.910156 C 139.402344 101.074219 135.300781 98.582031 131.359375 95.929688 C 126.726562 92.804688 122.257812 89.410156 117.8125 86.015625 C 113.894531 83.023438 109.980469 80.054688 106.066406 77.058594 C 102.917969 74.671875 99.476562 71.730469 99.316406 67.78125 C 99.210938 65.476562 100.269531 63.011719 99.238281 60.96875 C 98.816406 60.121094 98.074219 59.484375 97.359375 58.875 C 92.835938 54.980469 88.335938 51.058594 83.8125 47.160156 C 82.492188 45.996094 80.902344 44.773438 79.15625 45.066406 C 77.886719 45.277344 76.90625 46.257812 76.035156 47.214844 C 70.398438 53.414062 66.007812 60.757812 63.203125 68.65625 Z M 63.890625 73.347656 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#bfe5ef"
        d="M 57.328125 44.246094 C 56.957031 44.140625 56.5625 44.03125 56.21875 43.820312 C 55.875 43.609375 55.609375 43.289062 55.53125 42.894531 C 55.503906 42.65625 55.53125 42.390625 55.609375 42.175781 C 55.714844 41.726562 55.898438 41.300781 56.191406 40.957031 C 56.640625 40.429688 57.382812 40.191406 58.070312 40.242188 C 58.757812 40.296875 59.417969 40.613281 59.976562 41.039062 C 60.027344 41.089844 60.082031 41.117188 60.105469 41.171875 C 60.132812 41.25 60.105469 41.328125 60.105469 41.410156 C 59.789062 42.546875 59.101562 43.609375 58.203125 44.378906 Z M 57.328125 44.246094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ddb4a6"
        d="M 67.144531 44.378906 C 66.378906 43.847656 65.582031 43.316406 64.816406 42.785156 C 63.414062 41.832031 61.984375 40.851562 60.320312 40.535156 C 59.5 40.375 58.625 40.402344 57.859375 40.746094 C 57.089844 41.089844 56.457031 41.753906 56.269531 42.574219 C 56.058594 43.527344 56.507812 44.511719 57.195312 45.199219 C 57.882812 45.886719 58.757812 46.339844 59.605469 46.789062 C 61.324219 47.664062 63.042969 48.539062 64.738281 49.414062 C 64.816406 49.464844 64.921875 49.492188 65 49.464844 C 65.082031 49.441406 65.132812 49.332031 65.1875 49.253906 C 65.714844 47.929688 66.21875 46.605469 66.75 45.277344 Z M 67.144531 44.378906 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ddb4a6"
        d="M 64.183594 56.382812 C 62.117188 55.484375 60.054688 54.582031 58.015625 53.652344 C 57.648438 53.496094 57.25 53.308594 57.011719 52.992188 C 56.667969 52.566406 56.667969 51.957031 56.667969 51.429688 C 56.695312 50.445312 57.011719 49.574219 57.804688 48.9375 C 58.464844 48.40625 59.261719 47.824219 60.132812 47.71875 C 61.164062 47.609375 62.144531 48.167969 62.964844 48.804688 C 63.945312 49.546875 64.789062 50.421875 65.53125 51.402344 Z M 64.183594 56.382812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#bdbc85f7b0)">
        <path
          fill="#2d3235"
          d="M 89.210938 82.652344 L 64.710938 82.652344 C 63.125 82.652344 61.851562 81.351562 61.851562 79.789062 L 61.851562 26.035156 C 61.851562 24.445312 63.148438 23.171875 64.710938 23.171875 L 89.210938 23.171875 C 90.796875 23.171875 92.066406 24.472656 92.066406 26.035156 L 92.066406 79.761719 C 92.066406 81.378906 90.796875 82.652344 89.210938 82.652344 Z M 89.210938 82.652344 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#c4968a"
        d="M 59.394531 54.636719 C 59.234375 54.636719 59.074219 54.636719 58.917969 54.609375 C 58.175781 54.476562 57.515625 54.132812 57.117188 53.628906 C 56.667969 53.070312 56.324219 52.039062 56.507812 51.347656 C 56.5625 51.164062 56.746094 51.058594 56.933594 51.109375 C 57.117188 51.164062 57.222656 51.347656 57.171875 51.535156 C 57.039062 51.984375 57.328125 52.832031 57.648438 53.230469 C 57.9375 53.601562 58.441406 53.867188 59.023438 53.972656 C 59.84375 54.105469 60.53125 53.785156 61.113281 53.019531 C 61.851562 52.039062 61.851562 50.578125 61.140625 49.597656 C 61.03125 49.441406 61.058594 49.226562 61.21875 49.121094 C 61.378906 49.015625 61.589844 49.042969 61.695312 49.203125 C 62.59375 50.445312 62.566406 52.222656 61.667969 53.441406 C 61.058594 54.210938 60.265625 54.636719 59.394531 54.636719 Z M 59.394531 54.636719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfe5ef"
        d="M 58.8125 48.722656 C 58.996094 48.75 59.179688 48.832031 59.3125 48.988281 C 59.523438 49.253906 59.683594 49.546875 59.738281 49.890625 C 59.738281 49.917969 59.738281 49.96875 59.738281 49.996094 C 59.710938 50.050781 59.65625 50.101562 59.605469 50.128906 C 58.8125 50.5 58.015625 50.871094 57.460938 51.535156 C 57.171875 51.90625 56.957031 52.355469 56.773438 52.78125 C 56.71875 52.886719 56.667969 53.019531 56.589844 53.070312 C 56.484375 53.152344 56.324219 53.152344 56.269531 53.042969 C 56.246094 52.992188 56.246094 52.9375 56.246094 52.914062 C 56.113281 51.640625 56.535156 50.316406 57.382812 49.332031 C 57.515625 49.175781 57.648438 49.042969 57.832031 48.9375 C 57.992188 48.832031 58.175781 48.777344 58.359375 48.722656 C 58.464844 48.699219 58.546875 48.699219 58.652344 48.699219 Z M 58.8125 48.722656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ddb4a6"
        d="M 61.589844 58.238281 C 62.144531 58.71875 62.488281 59.40625 62.699219 60.121094 C 62.886719 60.730469 62.992188 61.421875 62.753906 62.003906 C 62.488281 62.640625 61.773438 63.089844 61.085938 63.0625 C 60.953125 63.0625 60.820312 63.039062 60.714844 62.957031 C 60.582031 62.851562 60.53125 62.691406 60.476562 62.535156 C 60.292969 61.84375 60.105469 61.15625 60.082031 60.441406 C 60.054688 59.722656 60.160156 58.980469 60.476562 58.347656 Z M 61.589844 58.238281 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfe5ef"
        d="M 59.128906 58.53125 C 59.261719 58.476562 59.417969 58.453125 59.578125 58.476562 C 60.449219 58.503906 61.269531 59.300781 61.507812 60.121094 C 61.507812 60.148438 61.507812 60.175781 61.507812 60.199219 C 61.507812 60.253906 61.429688 60.28125 61.351562 60.308594 C 60.796875 60.464844 60.34375 60.863281 60.027344 61.3125 C 59.683594 61.765625 59.472656 62.296875 59.234375 62.824219 C 58.703125 62.507812 58.28125 62.054688 57.992188 61.527344 C 57.699219 60.996094 57.539062 60.332031 57.753906 59.75 C 57.832031 59.539062 57.964844 59.167969 58.121094 58.980469 C 58.28125 58.824219 58.492188 58.71875 58.703125 58.636719 Z M 59.128906 58.53125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#efebe2"
        d="M 64.710938 81.511719 C 63.757812 81.511719 62.992188 80.742188 62.992188 79.789062 L 62.992188 26.035156 C 62.992188 25.082031 63.757812 24.3125 64.710938 24.3125 L 89.210938 24.3125 C 90.164062 24.3125 90.929688 25.082031 90.929688 26.035156 L 90.929688 79.761719 C 90.929688 80.714844 90.164062 81.484375 89.210938 81.484375 L 64.710938 81.484375 Z M 64.710938 81.511719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#2d3235"
        d="M 79.84375 25.875 L 74.050781 25.875 C 73.574219 25.875 73.175781 26.273438 73.175781 26.75 C 73.175781 27.226562 73.574219 27.625 74.050781 27.625 L 79.816406 27.625 C 80.292969 27.625 80.691406 27.226562 80.691406 26.75 C 80.691406 26.273438 80.320312 25.875 79.84375 25.875 Z M 79.84375 25.875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#c4968a"
        d="M 63.308594 61.15625 C 63.308594 61.898438 62.9375 62.640625 62.199219 63.222656 C 61.851562 63.488281 61.484375 63.726562 61.058594 63.886719 C 60.636719 63.910156 60.1875 63.910156 59.761719 63.910156 C 59.523438 63.910156 59.261719 63.910156 59.050781 63.832031 C 58.917969 63.78125 58.8125 63.699219 58.703125 63.59375 C 59.710938 63.699219 60.953125 63.355469 61.773438 62.691406 C 62.171875 62.375 62.621094 61.84375 62.621094 61.183594 C 62.621094 60.917969 62.566406 60.332031 62.488281 60.121094 C 62.222656 59.300781 61.667969 58.558594 60.925781 58.105469 C 60.769531 58 60.714844 57.789062 60.820312 57.628906 C 60.925781 57.472656 61.140625 57.417969 61.296875 57.523438 C 62.171875 58.054688 62.859375 58.929688 63.148438 59.910156 C 63.253906 60.226562 63.308594 60.890625 63.308594 61.15625 Z M 63.308594 61.15625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ddb4a6"
        d="M 105.746094 77.058594 C 106.382812 69.609375 105.007812 62.003906 101.75 55.296875 C 99.925781 51.480469 97.4375 47.691406 97.546875 43.476562 C 97.570312 42.335938 97.757812 41.089844 97.042969 40.191406 C 96.273438 39.234375 94.742188 39.207031 93.734375 39.898438 C 92.730469 40.585938 92.175781 41.78125 91.9375 43 C 91.617188 44.457031 91.699219 45.996094 91.832031 47.476562 C 92.199219 51.824219 93.074219 56.09375 94.476562 60.226562 C 94.660156 60.8125 94.871094 61.394531 94.847656 62.003906 C 94.820312 62.640625 94.554688 63.222656 94.34375 63.804688 C 93.578125 65.792969 93.152344 67.914062 93.125 70.0625 Z M 105.746094 77.058594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#c4968a"
        d="M 93.683594 69.824219 C 93.496094 69.824219 93.339844 69.664062 93.339844 69.476562 C 93.339844 67.199219 93.65625 64.917969 94.265625 62.71875 L 94.289062 62.640625 C 94.398438 62.269531 94.503906 61.898438 94.476562 61.554688 C 94.476562 61.261719 94.371094 60.96875 94.289062 60.730469 C 94.160156 60.308594 93.972656 59.910156 93.816406 59.511719 C 93.734375 59.351562 93.683594 59.195312 93.601562 59.035156 C 93.019531 57.65625 92.597656 56.226562 92.332031 54.765625 C 92.304688 54.582031 92.414062 54.394531 92.597656 54.371094 C 92.78125 54.34375 92.96875 54.449219 92.996094 54.636719 C 93.257812 56.039062 93.683594 57.445312 94.238281 58.769531 C 94.289062 58.929688 94.371094 59.085938 94.421875 59.246094 C 94.609375 59.671875 94.792969 60.09375 94.925781 60.519531 C 95.003906 60.785156 95.136719 61.15625 95.136719 61.554688 C 95.136719 62.003906 95.03125 62.425781 94.925781 62.851562 L 94.898438 62.929688 C 94.316406 65.078125 94 67.277344 94 69.503906 C 94.027344 69.691406 93.867188 69.824219 93.683594 69.824219 Z M 93.683594 69.824219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#c4968a"
        d="M 61.695312 70.777344 C 61.191406 71.519531 60.214844 72.421875 59.128906 72.550781 C 58.996094 72.578125 58.863281 72.578125 58.703125 72.578125 C 58.679688 72.578125 58.625 72.578125 58.597656 72.578125 C 58.308594 72.339844 58.042969 72.074219 57.777344 71.808594 C 57.832031 71.808594 57.859375 71.808594 57.910156 71.835938 L 57.964844 71.835938 C 58.335938 71.890625 58.703125 71.917969 59.050781 71.890625 C 59.867188 71.785156 60.6875 71.070312 61.140625 70.40625 C 61.695312 69.585938 61.90625 68.550781 61.695312 67.570312 C 61.5625 66.933594 61.269531 66.507812 60.820312 66.296875 C 60.371094 66.085938 59.949219 65.925781 59.550781 65.792969 C 60 65.6875 60.476562 65.632812 60.925781 65.582031 C 60.980469 65.609375 61.03125 65.632812 61.113281 65.660156 C 61.773438 65.953125 62.199219 66.5625 62.382812 67.410156 C 62.59375 68.601562 62.355469 69.824219 61.695312 70.777344 Z M 61.695312 70.777344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfe5ef"
        d="M 59.5 69.214844 C 59.394531 69.292969 59.023438 69.292969 58.890625 69.292969 C 58.652344 69.320312 58.386719 69.320312 58.148438 69.34375 C 58.121094 69.34375 58.070312 69.34375 58.042969 69.34375 C 58.015625 69.320312 57.992188 69.292969 57.964844 69.238281 C 57.832031 68.949219 57.699219 68.628906 57.671875 68.285156 C 57.621094 67.621094 58.097656 66.988281 58.679688 66.695312 C 59.179688 66.457031 59.761719 66.402344 60.320312 66.484375 C 60.425781 66.507812 60.476562 66.640625 60.398438 66.695312 C 60.132812 66.933594 59.976562 67.304688 59.867188 67.648438 C 59.789062 67.886719 59.683594 68.125 59.605469 68.363281 C 59.550781 68.496094 59.523438 68.628906 59.5 68.734375 C 59.472656 68.84375 59.523438 69.027344 59.5 69.132812 C 59.523438 69.1875 59.5 69.1875 59.5 69.214844 Z M 59.5 69.214844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfe5ef"
        d="M 97.308594 39.554688 L 95.453125 39.976562 C 95.691406 41.011719 95.878906 42.496094 95.851562 43.527344 C 95.851562 43.925781 95.824219 44.296875 95.878906 44.695312 C 95.929688 45.09375 96.089844 45.464844 96.40625 45.703125 C 96.511719 45.78125 97.175781 45.886719 97.308594 45.886719 C 97.519531 45.863281 97.675781 45.703125 97.730469 45.515625 C 97.78125 45.332031 97.78125 45.121094 97.757812 44.90625 C 97.652344 43.371094 97.519531 41.886719 97.652344 40.347656 L 97.652344 39.820312 C 97.675781 39.632812 97.492188 39.5 97.308594 39.554688 Z M 97.308594 39.554688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ddb4a6"
        d="M 59.738281 93.175781 C 60.238281 93.996094 60.742188 94.816406 61.246094 95.640625 C 61.878906 96.671875 62.542969 97.734375 63.546875 98.394531 C 63.730469 98.5 63.917969 98.609375 64.128906 98.609375 C 64.367188 98.582031 64.550781 98.394531 64.710938 98.210938 C 66.085938 96.644531 66.75 94.445312 66.429688 92.378906 C 66.191406 90.816406 65.425781 89.382812 64.578125 88.03125 C 64.128906 87.316406 63.652344 86.601562 63.125 85.910156 C 63.015625 85.753906 62.886719 85.59375 62.699219 85.515625 C 62.433594 85.382812 62.089844 85.433594 61.851562 85.59375 C 61.589844 85.753906 61.402344 85.992188 61.21875 86.230469 C 60.082031 87.792969 59.285156 89.570312 58.863281 91.449219 Z M 59.738281 93.175781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#990ee07532)">
        <path
          fill="#ddb4a6"
          d="M 69.789062 79.523438 C 63.945312 86.679688 59.605469 95.109375 57.144531 104.042969 C 59.101562 101.523438 61.058594 98.980469 62.410156 96.089844 C 63.414062 93.96875 64.074219 91.636719 65.503906 89.753906 C 66.933594 87.871094 69.421875 86.519531 71.617188 87.316406 C 71.882812 87.421875 72.144531 87.554688 72.304688 87.792969 C 72.515625 88.164062 72.382812 88.613281 72.226562 89.011719 C 70.132812 94.632812 68.042969 100.277344 65.953125 105.898438 C 64.894531 108.707031 63.835938 111.570312 62.117188 114.035156 C 57.433594 120.792969 48.4375 123.921875 44.734375 131.261719 C 44.074219 132.5625 43.597656 133.992188 42.59375 135.027344 C 40.898438 136.75 38.226562 136.933594 35.792969 137.015625 C 31.71875 137.148438 27.273438 137.171875 23.992188 134.734375 C 22.90625 133.9375 22.035156 132.90625 21.242188 131.820312 C 20.339844 130.574219 20.078125 128.957031 20.550781 127.5 C 22.617188 123.867188 24.679688 120.234375 26.1875 116.339844 C 29.152344 108.734375 30 100.355469 33.78125 93.121094 C 34.3125 92.140625 34.894531 91.160156 35.792969 90.496094 C 36.984375 89.648438 38.542969 89.488281 39.945312 89.039062 C 41.796875 88.457031 43.414062 87.289062 45 86.175781 C 46.878906 84.851562 48.730469 83.5 50.609375 82.175781 C 51.507812 81.511719 52.539062 80.847656 53.652344 80.929688 C 54.152344 80.980469 55.53125 81.882812 56.246094 82.121094 C 56.351562 82.148438 56.457031 82.175781 56.535156 82.175781 C 57.011719 82.175781 57.753906 81.195312 58.175781 80.742188 C 58.230469 80.691406 58.253906 80.664062 58.28125 80.636719 C 63.175781 76.261719 67.410156 69.664062 74.792969 67.144531 C 75.1875 67.011719 75.636719 67.011719 76.035156 67.199219 C 77.144531 67.675781 76.777344 69.875 76.21875 70.9375 C 74.554688 74.144531 72.039062 76.765625 69.789062 79.523438 Z M 69.789062 79.523438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#c4968a"
        d="M 58.148438 80.796875 C 58.015625 80.980469 57.882812 81.140625 57.753906 81.324219 C 57.433594 81.777344 57.117188 82.175781 56.746094 82.597656 C 54.972656 84.585938 53.308594 86.707031 51.851562 88.90625 C 51.800781 89.011719 51.667969 89.066406 51.5625 89.066406 C 51.507812 89.066406 51.429688 89.039062 51.375 89.011719 C 51.21875 88.90625 51.164062 88.695312 51.269531 88.535156 C 52.75 86.308594 54.417969 84.160156 56.21875 82.175781 C 56.324219 82.199219 56.429688 82.226562 56.507812 82.226562 C 57.011719 82.226562 57.726562 81.21875 58.148438 80.796875 Z M 58.148438 80.796875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ddb4a6"
        d="M 59.867188 94.074219 C 59.789062 96.460938 60.636719 99.003906 61.058594 101.335938 C 61.164062 101.921875 61.21875 102.53125 60.925781 103.035156 C 60.769531 103.273438 60.558594 103.484375 60.34375 103.671875 C 60.027344 103.933594 59.710938 104.226562 59.3125 104.359375 C 58.917969 104.492188 58.441406 104.464844 58.148438 104.171875 C 57.992188 104.015625 57.910156 103.777344 57.777344 103.589844 C 57.566406 103.300781 57.277344 102.371094 57.011719 102.160156 C 56.085938 101.390625 55.636719 100.859375 55.742188 99.667969 C 55.820312 98.476562 56.507812 97.097656 57.277344 96.167969 Z M 59.867188 94.074219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfe5ef"
        d="M 71.21875 69 C 71.617188 69.476562 72.355469 69.742188 72.9375 69.957031 C 73.28125 70.0625 73.707031 70.21875 74.078125 70.167969 C 74.660156 70.085938 75.1875 69.453125 75.636719 69.082031 C 75.929688 68.84375 76.246094 68.417969 76.539062 68.179688 C 76.695312 68.046875 76.855469 67.914062 76.960938 67.730469 C 77.066406 67.542969 77.09375 67.304688 77.015625 67.117188 C 76.933594 66.960938 76.777344 66.878906 76.644531 66.800781 C 76.007812 66.457031 75.242188 66.113281 74.578125 66.429688 C 74.316406 66.5625 73.867188 66.800781 73.628906 66.988281 C 72.914062 67.542969 72.488281 67.886719 71.773438 68.496094 Z M 71.21875 69 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfe5ef"
        d="M 69.84375 87.105469 C 69.207031 87.660156 68.785156 88.085938 68.308594 88.773438 C 67.804688 89.464844 67.4375 90.257812 67.332031 91.078125 C 67.304688 91.292969 67.304688 91.503906 67.332031 91.714844 C 67.410156 92.035156 67.621094 92.324219 67.832031 92.5625 C 68.125 92.910156 68.414062 93.226562 68.757812 93.492188 C 69.128906 93.757812 69.550781 93.917969 69.976562 93.917969 C 70.054688 93.917969 70.132812 93.917969 70.1875 93.863281 C 70.265625 93.835938 70.320312 93.757812 70.347656 93.703125 C 70.902344 92.933594 71.378906 92.140625 71.773438 91.292969 C 71.988281 90.867188 72.171875 90.445312 72.332031 89.992188 C 72.488281 89.570312 72.570312 89.144531 72.726562 88.722656 C 72.78125 88.5625 72.832031 88.375 72.832031 88.21875 C 72.808594 87.871094 72.542969 87.582031 72.25 87.371094 C 71.695312 87 70.980469 86.839844 70.320312 86.945312 Z M 69.84375 87.105469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#c4968a"
        d="M 57.25 104.414062 C 57.222656 104.414062 57.171875 104.414062 57.144531 104.386719 C 57.011719 104.332031 56.90625 104.199219 56.90625 104.066406 C 56.90625 102.769531 57.382812 101.417969 57.832031 100.199219 C 57.9375 99.90625 58.042969 99.640625 58.121094 99.375 C 58.625 97.867188 59.207031 96.328125 59.867188 94.84375 C 61.140625 91.902344 62.648438 89.066406 64.367188 86.386719 C 64.472656 86.230469 64.683594 86.175781 64.84375 86.28125 C 65 86.386719 65.054688 86.601562 64.949219 86.757812 C 63.253906 89.410156 61.746094 92.21875 60.503906 95.109375 C 59.867188 96.566406 59.285156 98.078125 58.785156 99.589844 C 58.703125 99.851562 58.597656 100.144531 58.492188 100.4375 C 58.230469 101.203125 57.9375 102 57.753906 102.769531 L 60.082031 99.457031 C 60.214844 99.191406 62.199219 95.71875 62.9375 93.96875 C 63.042969 93.703125 63.175781 93.4375 63.28125 93.175781 C 63.917969 91.691406 64.445312 90.417969 65.238281 89.515625 C 65.371094 89.382812 65.582031 89.355469 65.714844 89.488281 C 65.847656 89.621094 65.875 89.835938 65.742188 89.964844 C 65.027344 90.761719 64.527344 91.929688 63.917969 93.414062 C 63.8125 93.675781 63.707031 93.941406 63.574219 94.207031 C 62.78125 96.0625 60.769531 99.613281 60.664062 99.773438 C 60.664062 99.773438 60.664062 99.800781 60.636719 99.800781 L 57.515625 104.226562 C 57.460938 104.359375 57.355469 104.414062 57.25 104.414062 Z M 57.25 104.414062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
};

export default LiveTextLogo;
